import config from '@/common/config.js'

export function useSystemInfo() {
	//系统信息
	let systemInfo = {}




	systemInfo = uni.getSystemInfoSync()


	/** @deprecated @type { 'Douyin' | 'douyin_lite' | 'live_stream' | 'KUAISHOU' | 'WeChat' | string } 当前宿主软件环境 */
	const hostName = systemInfo.hostName
	/** @type { 'app' | 'web' | 'mp-toutiao' | 'mp-kuaishou' | 'mp-weixin' | string } 当前uniapp编译环境 */
	const uniPlatform = systemInfo.uniPlatform
	/** @type { 'ios' | 'android' | 'windows' | 'mac' | 'linux' | string } 当前操作系统 */
	const os = systemInfo.osName
	/** @type { string } 宿主版本号 */
	const hostVersion = systemInfo.hostVersion
	/** @type { number } 可使用窗口高度 */
	const windowHeight = systemInfo.windowHeight
	/** @deprecated @type { string } 设备型号 */
	const model = systemInfo.model
	/** @deprecated @type { string } 操作系统版本 */
	const system = systemInfo.system
	/** @deprecated @type { string } 手机品牌。H5 不支持 */
	const brand = systemInfo.brand || ''
	/** @deprecated @type { string } 引擎版本号 */
	const version = systemInfo.version
	/** @type { string } env文件中的appname */
	const appName = config.appName
	/** @deprecated @type { string } 客户端基础库版本 */
	const SDKVersion = systemInfo.SDKVersion
	/** @type { string } 设备ID */
	const deviceId = systemInfo.deviceId
	/** @type { string } 橙子版本号 */
	let appVersion = '0.0.1'
	/** @type { string } env 当前环境 */
	let env = 'env'









	/** @type { boolean } 是否为IOS */
	const isIOS = systemInfo.platform == 'ios'
	/** @type { boolean } 是否为Android */
	const isAndroid = systemInfo.platform == 'android'

	return {
		hostName,
		uniPlatform,
		os,
		hostVersion,
		windowHeight,
		model,
		system,
		brand,
		version,
		appName,
		SDKVersion,
		deviceId,
		appVersion,
		env,
		isIOS,
		isAndroid,
	}
}

/**
 * 比较版本号 ver1 , ver2 > 返回 1, < 返回 -1, = 返回 0
 * @param { string } ver1
 * @param { string } ver2
 */
export function compareVersion(ver1, ver2) {
	if (!ver1 || !ver2) {
		throw new Error('请填写版本号')
	}

	const ver1Arr = ver1.split('.')
	const ver2Arr = ver2.split('.')

	const len = Math.max(ver1Arr.length, ver2Arr.length)

	for (let i = 0; i < len; i++) {
		const num1 = parseInt(ver1Arr[i] || '0');
		const num2 = parseInt(ver2Arr[i] || '0');

		if (num1 > num2) {
			return 1;
		} else if (num1 < num2) {
			return -1;
		}
	}

	return 0
}

/**
 * 平台
 */
export function usePlatform() {

	/** @type { 'dy' | 'ks' | 'wx' | 'h5' | 'app' | string } */
	let apiPlatform = ''










	apiPlatform = 'h5'




	const systemInfo = uni.getSystemInfoSync()
	const isAndroid = systemInfo.osName === 'android'
	const isIOS = systemInfo.osName === 'ios'

	return {
		apiPlatform,
		isAndroid,
		isIOS
	}
}

/**
 * APP信息
 */
export function useAppInfo() {
	const isHammer = config.appName === '橙子知道'

	return {
		isHammer,
		isOrange: !isHammer,
	}
}

/**
 * 浏览器信息
 */
export function useBrowserInfo() {
	/** @type { boolean } 是否为微信浏览器 */
	let isWeChatBrowser = false
	let isDouyinBrowser = false

	let ua = window.navigator.userAgent.toLowerCase()
	isWeChatBrowser = ua.indexOf('micromessenger') != -1
	isDouyinBrowser = ua.indexOf('didi') != -1 || ua.indexOf('bytedance') != -1


	return {
		isWeChatBrowser,
		isDouyinBrowser
	}
}

/**
 * 启动信息
 */
export function useLaunchInfo() {
	const options = uni.getEnterOptionsSync()

	/** @type { string } 场景值 */
	const scene = String(options.scene)
	/** @type { boolean } 是否为直播场景 */
	const isLive = scene == '023009'
	/** @type { object } 携带参数 */
	const query = options.query || {}
	/** @type { boolean } 是否为iframe启动 */
	const isFrame = query.frame == '1'

	return {
		scene,
		isLive,
		query,
		isFrame
	}
}